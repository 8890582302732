import { version } from '../../package.json';

export const environment = {
  production: false,
  APP_NAME: 'SiteConnect',
  SERVICE_URL: 'https://api.staging.sitesoft.com/',
  LARAVEL_API_URL: 'https://api.staging.sitesoft.com/api/v1/',
  API_URL: 'https://api.staging.sitesoft.com/api/',
  MODE: 'STAGING',
  APP_VERSION: version,
  PROJECT_KNOWLEDGEBASE_URL: 'https://docs.sitesoft.com/',
  PROJECT_RELEASE_NOTES_URL: 'https://sitesoft.com/release-notes/',
  ANDROID_STORE_URL_MOBILE: 'https://play.google.com/store/apps/details?id=com.sitesoft.crm',
  ANDROID_STORE_URL_KIOSK: 'https://play.google.com/store/apps/details?id=com.sitesoft.geotab',
  IOS_STORE_URL_MOBILE: 'https://apps.apple.com/us/app/siteconnect/id1375246319',
  IOS_STORE_URL_KIOSK: 'https://apps.apple.com/us/app/siteconnect-visitor-kiosk/id1436775925',
  SALES_EMAIL: 'sales@sitesoft.com',
  SUPPORT_EMAIL: 'support@sitesoft.com',
  AES_STORAGE_KEY: '!w5ZS*0=J\'e6px]o/lUXO_^ku#9EfF',
  PRIVACY_POLICY_URL: 'https://www.sitesoft.com/privacy-policy/',
  TERMS_CONDITIONS_URL: 'https://www.sitesoft.com/terms/',
  URGENT_NOTICE_URL: 'https://docs.sitesoft.com/',
  GOOGLE_ANALYTICS: {
    MEASUREMENT_ID: 'G-4FBCQ6VBCV'
  },
  UPVOTY_LINK: 'https://roadmap.siteconnect.io/'
};
